import { Product, IRegion } from '.';
import { ButtonLabel, FundingSource, LoaderLabel, PlanStatus, Utils } from "../helpers";

export interface IProductPrices {
  prices: IAgreementPriceDetails[];
}

export interface IAgreementPriceDetails {
  AgreementId: number;
  AgreementType: string;
  bundleId: string;
  fundingSource: FundingSource.CREDIT_CARD | FundingSource.BANK_ACCOUNT;
  Location: {
    Code: string;
    Name: string;
  };
  Name: string;
  obligatedQuantity: number;
  PricingDetail: {
    Total: string;
    ItemDetail: IItemDetail[];
  };
  StartDate: string;
}

export interface IItemDetail {
  Amount: string;
  Discount: string;
  IsKeyItem: number;
  ItemId: number;
  Name: string;
  Promotion: string;
  Quantity: number;
  Tax: string;
  Total: string;
  UnitPrice: string;
}

export interface IClubCatalogPrices {
  green: IFundingSources;
  blue: IFundingSources;
  gray: IFundingSources;
  orange: IFundingSources;
}

interface IFundingSources {
  ach: IPriceItem;
  cc: IPriceItem;
}

interface IPriceItem {
  monthly: IAmount;
  startup: IAmount;
}

interface IAmount {
  amount: string;
  unitPrice: string;
}

export interface IProductVariations {
  variations: IVariations[];
}

interface IVariations {
  fundingSource: string[];
  planName: string;
}

export const createClubPrices = (planPrices: IAgreementPriceDetails[]): IClubCatalogPrices => {
  const defaultClubPrices: { [key: string]: { [key: string]: { [key: string]: { [key: string]: string } } } } = { green: { ach: { monthly: { amount: '-', unitPrice: '-' }, startup: { amount: '-' } }, cc: { monthly: { amount: '-', unitPrice: '-' }, startup: { amount: '-' } } }, blue: { ach: { monthly: { amount: '-', unitPrice: '-' }, startup: { amount: '-' } }, cc: { monthly: { amount: '-', unitPrice: '-' }, startup: { amount: '-' } } }, gray: { ach: { monthly: { amount: '-', unitPrice: '-' }, startup: { amount: '-' } }, cc: { monthly: { amount: '-', unitPrice: '-' }, startup: { amount: '-' } } }, orange: { ach: { monthly: { amount: '-', unitPrice: '-' }, startup: { amount: '-' } }, cc: { monthly: { amount: '-', unitPrice: '-' }, startup: { amount: '-' } } } };
  if (planPrices.length) {
    for (const prices of planPrices) {
      const monthlyItem = prices.PricingDetail.ItemDetail.find(({ Name }) => Name.toLowerCase().includes('monthly'));
      const monthlyAmount = Utils.roundOff(Number(monthlyItem?.Amount) - Number(monthlyItem?.Promotion)) ?? '-';
      const monthlyUnitPrice = monthlyItem?.UnitPrice ?? '-';
      const startUpItem = prices.PricingDetail.ItemDetail.find(({ Name }) => Name.toLowerCase().includes('startup'));
      const startUpAmount = Utils.roundOff(Number(startUpItem?.Amount) - Number(startUpItem?.Promotion)) ?? '-';

      const planName = prices.Name.toLowerCase();
      const fundingSource = prices.fundingSource.toLowerCase();
      const plan = defaultClubPrices[planName][fundingSource];
      plan.monthly.amount = String(monthlyAmount);
      plan.monthly.unitPrice = String(monthlyUnitPrice);
      plan.startup.amount = String(startUpAmount);
    }
  }

  return defaultClubPrices as unknown as IClubCatalogPrices;
}

interface IPerpetualOption {
  name: string;
  paymentOptionId: number;
  default: boolean;

}
interface IProductItem {
  perpetualOptions: IPerpetualOption[];
}

export interface IProductDetails {
  name: string;
  promoName: string;
  items: IProductItem[];
}

export interface IProduct {
  product: IProductDetails;
}

export interface IFacilityListData {
  id: string;
  name: string;
}

export interface IFacilityFundingSourceData extends IFacilityListData {
  bundleMonthlyFee: number | string;
  bundleStartupFee: number | string;
  defaultPromotion: string;
  planStatus: PlanStatus;
  promotionMonthlyFee: number | string;
  promotionStartupFee: number | string;
  region: string;
  loaderLabel: LoaderLabel;
  buttonLabel: ButtonLabel;
}

export interface ISingleFacilityData {
  CC: IFacilityFundingSourceData;
  ACH: IFacilityFundingSourceData;
}

export interface IFacilityData {
  CC: IFacilityFundingSourceData[];
  ACH: IFacilityFundingSourceData[];
}

export interface IFacilityFactoryData {
  CC: Product;
  ACH: Product;
}

export interface IFacilityLoaderData {
  facilityData: IFacilityData;
  regions: IRegion[];
}