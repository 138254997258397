import { format } from 'date-fns';
import { Timezone } from './constants';
import { allStatesList } from './states';
import { IPromoCode } from '../models';

export class Utils {
  /**
   * if param 'date' is there it will format the date and return
   * else it will format and return current date
   * @param date
   * @returns Date
   */
  static getFormattedDate = (date?: Date): string => {
    if (date) { return format(date, 'MM/dd/yyyy'); }
    const estDate = Utils.convertDateToTimeZone(Timezone.Eastern);
    return format(new Date(estDate), 'MM/dd/yyyy');
  }

  /**
   * Converting date to given timezone
   * @param timeZone
   * @param date
   * @returns Date
   */
  static convertDateToTimeZone = (timeZone: string, date = new Date()) => {
    const dateString = date.toLocaleString('en-US', { timeZone });
    return new Date(dateString);
  }

  static getRegionName = (name: string): string => {
    const selectedState = allStatesList.find((state) => state.abbreviation === name);
    return selectedState?.name ?? 'Chicago';
  }

  /**
   * Round off the number to 2 decimal places
   * @param number 
   * @returns 
   */
  static roundOff = (number: number): number => {
    return Math.round((number + 0.00001) * 100) / 100;
  }

  /**
   * Check the expiration of the given timestamp
   * @param timestamp 
   * @returns 
   */
  isExpired(timestamp: number): boolean {
    const currentTimestamp = (new Date()).getTime();
    return (currentTimestamp - timestamp > 0)
  }

  /**
   * Converts Object Keys to camelCase
   * @param obj 
   * @returns Object with converted keys
   */
  static objectKeysToCamelCase(obj: any): any {
    const toCamelCase = (str: string): string => str[0].toLowerCase() + str.slice(1);

    if (Array.isArray(obj)) {
      return obj.map(v => Utils.objectKeysToCamelCase(v));
    } else if (typeof obj === 'object') {
      return Object.keys(obj).reduce((result: { [key: string]: Object }, key) => {
        const camelCaseKey = toCamelCase(key);
        result[camelCaseKey] = Utils.objectKeysToCamelCase(obj[key]);
        return result;
      }, {});
    }
    return obj;
  }

  static getPromoCodeStatus(promoCode: IPromoCode): string {
    const startDate = new Date(promoCode.startDate).valueOf();
    const endDate = new Date(promoCode.endDate).setHours(23, 59, 59);
    const estTimeZoneDate = Utils.convertDateToTimeZone(Timezone.Eastern);
    const currentDate = estTimeZoneDate.getTime();
    return currentDate < startDate || currentDate > endDate ? 'Expired' : 'Active';
  };

  /**
   * Getting the available height for the table after substracting the height of navbar, page-header, table-header(plus some margin) and footer from window's inner-height
   * @param window 
   * @param document 
   * @param margin 
   * @returns 
   */
  static getAvailableViewportHeight(window: Window, document: Document, margin = 10): number {
    const nav = document?.querySelector('nav')?.getBoundingClientRect().height || 0;
    const header = document?.querySelector('#header')?.getBoundingClientRect().height || 0;
    const footer = document?.querySelector('.footer')?.getBoundingClientRect().height || 0;
    const tableHeader = document?.querySelector('.MuiToolbar-root')?.getBoundingClientRect().height || 0;
    return window.innerHeight - (header + footer + nav + tableHeader + margin);
  }

  /**
   * Capitalizing first letter of the word
   * @param word 
   * @returns string
   */
  static capitalizeFirstLetter(word: string | undefined): string {
    return word ? word[0].toUpperCase() + word.slice(1) : '';
  };
}

