import { useState, useMemo, useRef, useLayoutEffect } from 'react';
import { useLoaderData } from 'react-router-dom';
import MaterialReactTable, { MRT_ColumnDef, MRT_Row, MRT_TableInstance } from 'material-react-table';
import Button from 'react-bootstrap/Button';
import CheckIcon from '../../components/CheckIcon';
import ComparePlans from '../../components/ComparePlans';
import AdminService from '../../services/AdminService';
import PopupService from '../../services/PopupService';
import ProductService from '../../services/ProductService';
import { StorageFactory } from '../../services/StorageFactory';
import YextService from '../../services/YextService';
import { ComparisonStatus, Defaults, MigrationStatus, ObligatedQuantity, Plan, Utils, planMap, plans, responseMessages } from '../../helpers';
import { ILocation, IPlanRowData } from '../../models';
import './style.scss';

const MigrateProducts = () => {
  const tableInstanceRef = useRef<MRT_TableInstance<IPlanRowData>>(null);
  const [clubs, setClubs] = useState(useLoaderData() as IPlanRowData[]);
  const [open, setOpen] = useState(false);
  const [facilityId, setFacilityId] = useState<string>('');
  const [facilityName, setFacilityName] = useState<string>('');
  const [activeYextPlans, setActiveYextPlans] = useState<string[]>([]);
  const [activeCatalogPlans, setActiveCatalogPlans] = useState<string[]>([]);
  const [inactiveCatalogPlans, setInactiveCatalogPlans] = useState<string[]>([]);
  const [tableHeight, setTableHeight] = useState(Defaults.TableHeight);

  useLayoutEffect(() => {
    const handleResize = () => { setTableHeight(Utils.getAvailableViewportHeight(window, document)); }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); };
  }, []);

  /** Clear all the selected rows */
  const handleClearSelection = (): void => {
    tableInstanceRef.current?.resetRowSelection();
  }

  /** Run migration for all the selected rows */
  const handleRequestMigration = async (): Promise<void> => {
    const selectedRows = tableInstanceRef.current?.getSelectedRowModel().rows;
    await migrateProducts(selectedRows);
  }

  /**
   * Run migration for all the clubs synchronously
   * @param selectedClubs 
   */
  const migrateProducts = async (selectedClubs: MRT_Row<IPlanRowData>[] = []): Promise<void> => {
    for (let club of selectedClubs) {
      await migrateProduct(club);
    }
  }

  /**
   * Run migration for a single club
   * @param row 
   */
  const migrateProduct = async (row: MRT_Row<IPlanRowData>): Promise<void> => {
    setMigrationStateInRow(row, MigrationStatus.MIGRATING);
    const migrationResponse = await ProductService.requestMigrateProducts(row.id);
    setMigrationStateInRow(row, migrationResponse ? MigrationStatus.MIGRATED : MigrationStatus.FAILED);
  }

  /**
   * Set the migration state
   * @param row 
   * @param status 
   */
  const setMigrationStateInRow = (row: MRT_Row<IPlanRowData>, status: MigrationStatus): void => {
    row.original.status = status;
    clubs[row.index] = row.original;
    setClubs([...clubs]);
  }

  /**
   * Set the comparison state
   * @param row 
   * @param status 
   */
  const setCompareStateInRow = (row: MRT_Row<IPlanRowData>, status: ComparisonStatus): void => {
    const rowData = { ...row.original, compare: status };
    setClubs(prevState => {
      prevState[row.index] = rowData;
      return [...prevState]
    });
  }

  const columns = useMemo<MRT_ColumnDef<IPlanRowData>[]>(
    () => [
      {
        accessorKey: 'Name',
        header: 'Name',
        muiTableHeadCellProps: {
          align: 'left',
        },
        muiTableBodyCellProps: {
          align: 'left',
        },
        enableColumnFilter: true,
        enableSorting: true,
        muiTableHeadCellFilterTextFieldProps: { placeholder: 'Search' }
      },
      {
        accessorKey: 'Id',
        header: 'ID',
        size: 100,
        enableColumnFilter: true,
        enableSorting: true,
        muiTableHeadCellFilterTextFieldProps: { placeholder: 'Search' }
      },
      {
        accessorKey: 'Region',
        header: 'Region',
        size: 80,
        enableColumnFilter: true,
        enableSorting: true,
        muiTableHeadCellFilterTextFieldProps: { placeholder: 'Search' }
      },
      {
        accessorKey: 'Status',
        header: 'Status',
        size: 100,
        enableColumnFilter: true,
        enableSorting: true
      },
      {
        accessorKey: 'hasBlue',
        header: 'Has Blue',
        size: 70,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: CheckIcon
      },
      {
        accessorKey: 'hasGreen',
        header: 'Has Green',
        size: 80,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: CheckIcon
      },
      {
        accessorKey: 'hasGray',
        header: 'Has Gray',
        size: 70,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: CheckIcon
      },
      {
        accessorKey: 'hasOrange',
        header: 'Has Orange',
        size: 80,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: CheckIcon
      },
      {
        accessorKey: 'hasBlueNC',
        header: 'Has Blue NC',
        size: 80,
        enableColumnFilter: false,
        enableSorting: false,
        Cell: CheckIcon
      },
      {
        accessorKey: 'compare',
        header: 'Diff. in Active Plans (Catalog & Yext)',
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ row, cell }) => (
          <div className='compare-plans'>
            <Button className={String(cell.getValue())} onClick={() => comparePlans(row)} data-testid={`comparePlansButton-${row.original.Id}`}>{String(cell.getValue())}</Button>
          </div>
        )
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const comparePlans = async (row: MRT_Row<IPlanRowData>) => {
    setCompareStateInRow(row, ComparisonStatus.COMPARING);

    const activePlansInYext = getActivePlansInYext(row.original);
    const activePlansInCatalog = await getActivePlansInCatalog(row.id);

    const inactivePlans = getInactivePlansInCatalog(activePlansInYext, activePlansInCatalog);
    if (!inactivePlans.length) {
      setCompareStateInRow(row, ComparisonStatus.IDENTICAL);
    } else {
      setCompareStateInRow(row, ComparisonStatus.DIFFERENT);
      setInactiveCatalogPlans(inactivePlans);
      showDifference(row.original, activePlansInYext, activePlansInCatalog);
    }
  };

  const showDifference = (facilityData: IPlanRowData, activePlansInYext: string[], activePlansInCatalog: string[]) => {
    setFacilityId(facilityData.Id);
    setFacilityName(facilityData.Name);
    setActiveYextPlans(activePlansInYext);
    setActiveCatalogPlans(activePlansInCatalog);
    setOpen(true);
  }

  /**
   * Comparing active plans in yext and catalog
   * plans active in yext should also active in catalog
   * @param activePlansInYext
   * @param activePlansInCatalog
   * @returns string[]
   */
  const getInactivePlansInCatalog = (activePlansInYext: string[], activePlansInCatalog: string[]): string[] => {
    return activePlansInYext.filter(plan => !activePlansInCatalog.includes(plan));
  }

  /**
   * Getting the active plans in yext
   * @param rowData 
   * @returns string[]
   */
  const getActivePlansInYext = (rowData: IPlanRowData): string[] => {
    const activePlansInYext: string[] = [];
    planMap.forEach((value: string, key: string) => {
      if (rowData[key]) {
        activePlansInYext.push(value);
      }
    })
    return activePlansInYext;
  };

  /**
   * Getting the active plans in catalog
   * @param facilityId 
   * @returns string[]
   */
  const getActivePlansInCatalog = async (facilityId: string): Promise<string[]> => {
    const productCatalogPlans = await ProductService.getVariationByFacility(facilityId);
    const activePlans = productCatalogPlans.variations.map(async ({ planName }) => {
      if (planName.toUpperCase() === Plan.BLUE) {
        return await getBluePlanName(facilityId, planName);
      }
      return planName;
    });
    return await Promise.all(activePlans);
  };

  /**
   * Get the exact blue plan i.e Blue Commit or Blue Non-commit
   * @param facilityId
   * @param planName
   * @returns string
   */
  const getBluePlanName = async (facilityId: string, planName: string): Promise<string> => {
    const { prices } = await ProductService.getPricesByPlanName(facilityId, planName);
    return prices[0].obligatedQuantity === ObligatedQuantity.NON_COMMIT ? plans.get('BLUE_NON-COMMIT')!.name : planName;
  }

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleSubmit = async (): Promise<void> => {
    PopupService.showLoader('Activating Plans in Catalog');
    setOpen(false);
    try {
      const planNotFound = inactiveCatalogPlans.map(async (plan: string) => {
        const agreementId = plans.get(plan.toUpperCase())?.id;
        const response = await AdminService.changeProductStatus(agreementId, facilityId);
        if (response.message === responseMessages.productNotExists) {
          return plan;
        } return null;
      })

      const promisesResponse = await Promise.all(planNotFound);

      const plansNotExists = promisesResponse.filter((plan: string | null) => plan !== null);
      if (!plansNotExists.length) {
        const selectedRow: any = tableInstanceRef.current?.getRow(facilityId);
        setCompareStateInRow(selectedRow!, ComparisonStatus.IDENTICAL);
        PopupService.hideLoader();
      } else {
        PopupService.showError(getMessage(plansNotExists));
      }
    } catch (err) {
      PopupService.showError('Please try again later');
    }
  };

  /**
   * Generating the HTML error message for the alert popup
   * @param plansNotExists 
   * @returns HTML string
   */
  const getMessage = (plansNotExists: (string | null)[]): string => {
    let list = '';
    plansNotExists.forEach((plan) => { list += `<li>${plan}</li>` });
    return `<div class="not-exists-message">The following plans do not exist in the catalog:
      <ul>${list}</ul>
      <p>Please check the MoSo configuration and retry migration.<p>
    </div>`;
  }

  /**
   * Showing the different status of the migration in the actions column
   * @param row
   * @returns React.JSX.Element
   */
  const showMigrationStatus = (row: MRT_Row<IPlanRowData>): React.JSX.Element => {
    const status = row.original.status;
    return (
      <div>
        {(() => {
          if (status === MigrationStatus.MIGRATING) {
            return (<div className='processing' data-testid='migrating'></div>)
          } else if (status === MigrationStatus.MIGRATED) {
            return (<button className='link green' onClick={() => migrateProduct(row)} data-testid='migrated' title='Migrate Again'>Migrated</button>)
          } else if (status === MigrationStatus.FAILED) {
            return (<button className='link red' onClick={() => migrateProduct(row)} data-testid='failed' title='Migrate Again'>Failed</button>)
          } else {
            return (<Button onClick={() => migrateProduct(row)} data-testid='migrate'>Migrate</Button>)
          }
        })()}
      </div>
    )
  }

  return (
    <div className='migration-page' data-testid='migrateProducts'>
      <div className='migration-header' id='header'>
        <h5>Select all the clubs you want to run migration for:</h5>
      </div>
      <MaterialReactTable
        columns={columns}
        data={clubs}
        displayColumnDefOptions={{ 'mrt-row-actions': { header: 'Migration', size: 90 } }}
        enableColumnActions={false}
        enableDensityToggle={false}
        enableBottomToolbar={false}
        enableGlobalFilter
        enableStickyHeader
        enablePagination={false}
        enableRowActions
        enableRowSelection
        getRowId={originalRow => originalRow.Id}
        muiTableBodyCellProps={{ align: 'center' }}
        muiTableHeadCellProps={{ align: 'center' }}
        muiTablePaperProps={{ sx: { boxShadow: 0 } }}
        muiTableProps={{ sx: { tableLayout: 'fixed', boxShadow: 0 } }}
        muiTableContainerProps={{ sx: { maxHeight: tableHeight } }}
        positionActionsColumn='last'
        positionToolbarAlertBanner='top'
        renderRowActions={({ row }) => showMigrationStatus(row)}
        renderTopToolbarCustomActions={({ table }) => (
          <div className='toolbar-actions'>
            <div className='selected' data-testid='selected-clubs'>{!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected() && <span>No row selected</span>}</div>
            <Button disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()} onClick={handleClearSelection} className='orange-btn' variant='danger'>Clear Selection</Button>
            <Button disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()} onClick={handleRequestMigration}>Request Migration</Button>
          </div>
        )}
        state={{ density: 'compact' }}
        tableInstanceRef={tableInstanceRef}
      />
      <ComparePlans
        open={open}
        facilityName={facilityName}
        activeYextPlans={activeYextPlans}
        activeCatalogPlans={activeCatalogPlans}
        handleSubmit={handleSubmit}
        handleClose={handleClose}>
      </ComparePlans>
    </div>
  );
}

/**
 * Loading the yext clubs data, utilizing storage if cached data is present
 * @returns Promise<IPlanRowData[]>
 */
export const migrateProductsLoader = async (): Promise<IPlanRowData[]> => {
  PopupService.showLoader('Getting Products Details');
  const CACHE_KEY = 'YEXT_ALL_FACILITY_DATA';
  const sessionStore = StorageFactory(sessionStorage);
  let clubs = sessionStore.getItem(CACHE_KEY);

  try {
    if (!clubs) {
      const { response: { entities } } = await YextService.getAllFacilities();
      sessionStore.setItemWithTTL(CACHE_KEY, JSON.stringify(entities), 200);
      clubs = JSON.stringify(entities);
    }
    PopupService.hideLoader();
    return formatData(JSON.parse(clubs));
  } catch (error: any) {
    PopupService.hideLoader();
    throw new Error(error.message);
  }

}

/**
 * Formats yext data to be used by MaterialReactTable
 * @param yextEntities : ILocation[]
 * @returns IPlanRowData[]
 */
const formatData = (yextEntities: ILocation[]): IPlanRowData[] => {
  const formattedClubData = [];
  const planMap = {
    'hasBlue': Plan.BLUE,
    'hasGreen': Plan.GREEN,
    'hasGray': Plan.GRAY,
    'hasOrange': Plan.ORANGE,
    'hasBlueNC': Plan.BLUE_NC
  };
  for (const entity of yextEntities) {
    const club = {} as IPlanRowData;
    const plans: string[] = entity.c_planOptions;
    club['Name'] = entity.geomodifier;
    club['Id'] = entity.meta.id;
    club['Region'] = entity.isoRegionCode;
    club['Status'] = entity.c_status || '';
    club['compare'] = ComparisonStatus.COMPARE;
    club['status'] = MigrationStatus.MIGRATE;
    for (let [key, plan] of Object.entries(planMap)) {
      club[key] = (plans?.length > 0) ? plans.indexOf(plan) !== -1 : false;
    };
    formattedClubData.push(club);
  }
  return formattedClubData;
}

export default MigrateProducts;