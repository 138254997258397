import { Link, useRouteError } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const MigrateProductsError = () => {
  const error = useRouteError() as { message: string };

  return (
    <div className='error-container'>
      <div className='error'>
        <h3>Error occurred while loading the page</h3>
        <h5>Reason : {error?.message}</h5>
        <Link to='/products'>
          <Button>Back to the Products Page</Button>
        </Link>
      </div>
    </div>
  )
}

export default MigrateProductsError;
