import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { promoCodesActions } from './promo-code-slice';
import PopupService from '../services/PopupService';
import PromoCodesService from '../services/PromoCodesService';
import { IPromoCode } from '../models';

export const fetchPromoCodes = () => {
  PopupService.showLoader('Getting the promocodes');
  return async (dispatch: Dispatch<UnknownAction>) => {
    try {
      const promoCodesData = await PromoCodesService.getAllPromoCodes();
      dispatch(promoCodesActions.replacePromoCodes(promoCodesData || []));
      PopupService.hideLoader();
    } catch (error) {
      const errorMessage = PromoCodesService.getErrorMessage(error);
      PopupService.showError(errorMessage);
    }
  };
};

export const createPromoCode = (data: IPromoCode) => {
  PopupService.showLoader('Creating the Promocode');  
  return async (dispatch: Dispatch<UnknownAction>) => {
    try {
      const response = await PromoCodesService.createPromoCode(data);
      if (response?.success) {
        dispatch(promoCodesActions.updateList(data));
        PopupService.showSuccess('The promocode has been successfully created');
      } else {
        PopupService.showError(response.message);
      }
    } catch (error) {
      const errorMessage = PromoCodesService.getErrorMessage(error);
      PopupService.showError(errorMessage);
    }
  };
};

export const updatePromoCode = (data: IPromoCode) => {
  PopupService.showLoader('Updating the Promocode');
  return async (dispatch: Dispatch<UnknownAction>) => {
    try {
      const response = await PromoCodesService.updatePromoCode(data);
      if (response?.success) {
        dispatch(promoCodesActions.updateList(data));
        PopupService.showSuccess('The promocode has been successfully updated');
      } else {
        PopupService.showError(response.message);
      }
    } catch (error) {
      const errorMessage = PromoCodesService.getErrorMessage(error);
      PopupService.showError(errorMessage);
    }
  };
};

export const expirePromoCode = (data: IPromoCode) => {
  PopupService.showLoader('Setting the Expiry Date');
  return async (dispatch: Dispatch<UnknownAction>) => {
    try {
      const response = await PromoCodesService.expirePromoCode(data.promoCode, data.endDate);
      if (response?.success) {
        dispatch(promoCodesActions.updateList(data));
        PopupService.showSuccess('The expiry date has been successfully updated');
      } else {
        PopupService.showError(response.message);
      }
    } catch (error) {
      const errorMessage = PromoCodesService.getErrorMessage(error);
      PopupService.showError(errorMessage);
    }
  };
};
