import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Authenticator } from '@aws-amplify/ui-react';
import { Auth, Hub } from 'aws-amplify';
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import { CognitoUser } from "../models";

const RootLayout = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<CognitoUser | undefined>();

  const assessLoggedInState = () => {
    Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        setUser(cognitoUser);
      })
      .catch(() => {
        console.log('not logged in');
        setUser(undefined);
      })
  }

  useEffect(() => {
    assessLoggedInState();
    const setAuthListener = () => {
      Hub.listen('auth', (authData) => {
        switch (authData.payload.event) {
          case 'signIn':
            setUser(authData.payload.data);
            break;
          case 'signUp':
            console.log('user signed up');
            break;
          case 'signOut':
            console.log('user signed out');
            navigate(0);
            break;
          case 'signIn_failure':
            console.log('user sign in failed');
            break;
          case 'configured':
            console.log('the Auth module is configured');
        }
      });
    }
    setAuthListener();
  }, [navigate]);

  return user ? (
    <Container fluid className="content">
      <Navigation />
      <Container fluid>
        <Outlet />
      </Container>
      <Footer />
    </Container>) : (<Authenticator loginMechanisms={['email']} hideSignUp={true}></Authenticator>);
};

export default RootLayout;