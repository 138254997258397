import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { clubsActions } from './clubs-slice';
import PopupService from '../services/PopupService';
import YextService from '../services/YextService';
import { errorMessages } from '../helpers';

export const getRegions = () => {
  PopupService.showLoader('Getting the regions');
  return async (dispatch: Dispatch<UnknownAction>) => {
    try {
      const { response } = await YextService.getAllRegions();
      const regions = response.folders.slice(1);
      if (!regions.length) { throw new Error('No Regions Found') };
      dispatch(clubsActions.replaceClubRegions(regions || []));
      PopupService.hideLoader();
    } catch (error) {
      PopupService.showError(errorMessages.generic);
    }
  };
};
