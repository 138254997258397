import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { UnknownAction } from '@reduxjs/toolkit';
import { getRegions } from '../store/clubs-actions';
import { Region, Utils } from '../helpers';
import { IRegion } from '../models';

const Regions = () => {
  const dispatch = useDispatch();
  const { items: regions } = useSelector((state: any) => state.clubs);

  useEffect(() => {
    if(!regions.length) {
      dispatch(getRegions() as unknown as UnknownAction);
    }
  }, [dispatch, regions.length]);
  

  return (regions.length &&
    <div className='regions-container'>
      {regions.map((region: IRegion) => {
        return (
          region.name.toLowerCase() === Region.NEW_YORK
            ? <Link key={region.id} to={`${region.name.toLowerCase()}/groups`}>{Utils.getRegionName(region?.name)}</Link>
            : <Link key={region.id} to={region.name.toLowerCase()}>{Utils.getRegionName(region?.name)}</Link>
        )
      })}
    </div>
  )
}

export default Regions;
