import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link, useRouteError } from 'react-router-dom';
import PopupService from '../../services/PopupService';

const PromoCodesError = () => {
  const error = useRouteError() as { message: string };

  useEffect(() => {
    PopupService.hideLoader();
  }, []);

  return (
    <div className='error-container'>
      <div className='error'>
        <h3>Error occurred while loading the page</h3>
        <h5>Reason : {error?.message}</h5>
        <Link to='/'>
          <Button>Back to the Home Page</Button>
        </Link>
      </div>
    </div>
  )
}

export default PromoCodesError;
