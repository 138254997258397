import axios from 'axios';
import UserService from './UserService';
import { errorMessages } from '../helpers';
import { IPromoCode, IPromoCodeResponse, IPromoCodePayload, PromoCode } from "../models";

const PromoCodesService = {
  promotionsAPIUrl: `${process.env.REACT_APP_DOMAIN_URL}/${process.env.REACT_APP_PROMOTIONS_API}`,
  async getPromoCode(promocode: string): Promise<IPromoCode> {
    const user = await UserService.getAuthenticatedUser();
    const options = {
      headers: {
        'Authorization': `Bearer ${user?.signInUserSession.idToken.jwtToken}`,
        'Content-Type': String(process.env.REACT_APP_CONTENT_TYPE),
        'x-api-key': String(process.env.REACT_APP_PROMOTIONS_API_KEY)
      }
    };
    const response = await axios.get(`${this.promotionsAPIUrl}/${promocode}`, options);
    return response.data;
  },
  async getAllPromoCodes(): Promise<IPromoCode[]> {
    const user = await UserService.getAuthenticatedUser();
    const options = {
      headers: {
        'Authorization': `Bearer ${user?.signInUserSession.idToken.jwtToken}`,
        'Content-Type': String(process.env.REACT_APP_CONTENT_TYPE),
        'x-api-key': String(process.env.REACT_APP_PROMOTIONS_API_KEY)
      }
    };
    const response = await axios.get(this.promotionsAPIUrl, options);
    return response.data;
  },
  async createPromoCode(data: IPromoCode): Promise<IPromoCodeResponse> {
    const payload = new PromoCode(data);    
    const user = await UserService.getAuthenticatedUser();
    const options = {
      headers: {
        'Authorization': `Bearer ${user?.signInUserSession.idToken.jwtToken}`,
        'Content-Type': String(process.env.REACT_APP_CONTENT_TYPE),
        'x-api-key': String(process.env.REACT_APP_PROMOTIONS_API_KEY)
      }
    };
    const response = await axios.post(`${this.promotionsAPIUrl}/create`, payload, options);
    return response.data;
  },
  async expirePromoCode(promoCode: string, endDate: string): Promise<IPromoCodeResponse> {
    const user = await UserService.getAuthenticatedUser();
    const options = {
      headers: {
        'Authorization': `Bearer ${user?.signInUserSession.idToken.jwtToken}`,
        'Content-Type': String(process.env.REACT_APP_CONTENT_TYPE),
        'x-api-key': String(process.env.REACT_APP_PROMOTIONS_API_KEY)
      },
      params: { endDate }
    };
    const response = await axios.put(`${this.promotionsAPIUrl}/expire/${promoCode}`, null, options);
    return response.data;
  },
  async updatePromoCode(data: IPromoCode): Promise<IPromoCodeResponse> {
    const promoCode = data.promoCode.toLowerCase();
    const payload = this.getUpdatePromoCodePayload(data);
    const user = await UserService.getAuthenticatedUser();
    const options = {
      headers: {
        'Authorization': `Bearer ${user?.signInUserSession.idToken.jwtToken}`,
        'Content-Type': String(process.env.REACT_APP_CONTENT_TYPE),
        'x-api-key': String(process.env.REACT_APP_PROMOTIONS_API_KEY)
      }
    };
    const response = await axios.put(`${this.promotionsAPIUrl}/update/${promoCode}`, payload, options);
    return response.data;
  },
  getUpdatePromoCodePayload(data: IPromoCode): IPromoCodePayload {    
    delete data.status;
    delete data.tableData;
    delete data.useCount;
    const payload = { ...data } as IPromoCodePayload;
    delete payload.promoCode;
    return payload;
  },
  getErrorMessage(error: any): string {
    return axios.isAxiosError(error) && !error.response?.data.success
      ? error.response?.data.message
      : error.message || errorMessages.generic;
  }
};

export default PromoCodesService;