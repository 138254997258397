import { configureStore } from '@reduxjs/toolkit';
import clubsReducer from './clubs-slice';
import promoCodesReducer from './promo-code-slice';

const store  = configureStore({
  reducer: {
    clubs: clubsReducer,
    promoCodes: promoCodesReducer
  }
})

export default store;