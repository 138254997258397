import { Link, useRouteError } from 'react-router-dom';

const ClubsGroupsError = () => {
  const error = useRouteError() as { message: string };

  return (
    <div className='error'>
      <h2>Error</h2>
      <p>{error.message}</p>
      <Link to='/'>Back to the Regions Page</Link>
    </div>
  )
}

export default ClubsGroupsError;
