import { IItemDetail } from "./product.model";

export class PriceItem {
  private _amount: number;
  private _discount: number;
  private _name: string;
  private _quantity: number;
  private _tax: number;
  private _total: number;
  private _unitPrice: number; 

  isAnnualFee = false;
  isMonthlyFee = false;
  isStartupFee = false;

  constructor(item: IItemDetail) {
    this._amount = Number(item.Amount);
    this._discount = Number(item.Promotion) + Number(item.Discount);
    this._name = item.Name;
    this._quantity = item.Quantity;
    this._tax = Number(item.Tax);
    this._total = Number(item.Total);
    this._unitPrice = Number(item.UnitPrice);
  }

  static createPriceItems(items: IItemDetail[]): PriceItem[] {
    const priceItems: PriceItem[] = [];
    items.forEach(item => {
      const name = item.Name.toLowerCase();
      if(name.includes('annual')) {
        priceItems.push(new AnnualFeeItem(item));
      }
      if(name.includes('monthly')) {
        priceItems.push(new MonthlyFeeItem(item));
      }
      if(name.includes('startup')) {
        priceItems.push(new StartupFeeItem(item));
      }
    });
    return priceItems;
  }  

  static getTotalAmount(prices: PriceItem[]): number {
    let totalAmount = 0;
    prices.forEach(item => {
      if (item.isAnnualFee) return;
      if (item.isMonthlyFee) { totalAmount += item.total; }
      if (item.isStartupFee) { totalAmount += item.total; }
    });
    return totalAmount;
  }

  get amount(): number {
    return this._amount;
  }

  get discount(): number {
    return this._discount;
  }

  get totalDiscount(): number {
    return this._quantity * this._discount;
  }

  get name(): string {
    return this._name;
  }

  get tax(): number {
    return this._tax;
  }
  
  get totalTax(): number {
    return this._quantity * this._tax;
  }

  get total(): number {
    return this._total;
  }

  get unitPrice(): number {
    return this._unitPrice;
  }
}

class AnnualFeeItem extends PriceItem {
  isAnnualFee: boolean;
  constructor(item: IItemDetail) {
    super(item);
    this.isAnnualFee = true;
  }
}

class MonthlyFeeItem extends PriceItem {
  isMonthlyFee: boolean;
  constructor(item: IItemDetail) {
    super(item);
    this.isMonthlyFee = true;
  }
}

class StartupFeeItem extends PriceItem {
  isStartupFee: boolean;
  constructor(item: IItemDetail) {
    super(item);
    this.isStartupFee = true;
  }
}