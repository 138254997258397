import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Button } from 'react-bootstrap';
import './style.scss';

const ComparePlans = (props: any) => {
  const handleSubmit = async () => {
    props.handleSubmit();
  };

  return (
    <Dialog maxWidth="lg" open={props.open} onClose={props.handleClose}>
      <DialogTitle data-testid='title'>WARNING: Different Active Plans in Blink {props.facilityName}</DialogTitle>
      <DialogContent>
        <DialogContentText data-testid='content'>We have found a difference between the number of plans activated from YEXT and number of active plans in CATALOG:
        </DialogContentText>
        <div className="dialog-content">
          <div className="col-md-6 plans">
            <p>In Yext, the active plans are:</p>
            <ul>
              {props.activeYextPlans?.map((item: string, index: number) => <li key={`activePlanInYext-${index}`}>{item}</li>)}
            </ul>
          </div>
          <div className="col-md-6 plans">
            <p>In Catalog, the active plans are:</p>
            <ul>
              {props.activeCatalogPlans?.map((item: string, index: number) => <li key={`activePlansInCatalog-${index}`}>{item}</li>)}
            </ul>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="m-5">
        <Button className="mx-4 px-5 py-3 orange-btn" data-testid='check-later' variant="danger" onClick={props.handleClose}>Check again later</Button>
        <Button className="px-5 py-3" data-testid='make-identical-plans' variant="primary" onClick={handleSubmit}>Make CATALOG's active plans identical to YEXT</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ComparePlans;