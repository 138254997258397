import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { FundingSource, plans } from '../../helpers';
import './style.scss';

function ProductVariations() {
  return (
    <Col xs={12} className='variations-container' data-testid='product-variations'>
      <Row className='text-center justify-content-center'>
        {[...plans.values()].map(({ name, shortName }) => {
          return (
            <Col md={4} key={name} data-testid={name}>
              <div className={`plan ${shortName}`} data-testid='plan'>
                <div className='name'>{name}</div>
                <div className='variations'>
                  <Link className='btn' key='cc' to={{ pathname: shortName, search: `?fundingSource=${FundingSource.CREDIT_CARD}` }} state={{ name, shortName }}>
                    {FundingSource.CREDIT_CARD}
                  </Link>
                  <Link className='btn' key='ach' to={{ pathname: shortName, search: `?fundingSource=${FundingSource.BANK_ACCOUNT}` }} state={{ name, shortName }}>
                    {FundingSource.BANK_ACCOUNT}
                  </Link>
                </div>
              </div>
            </Col>
          )
        })}
      </Row>
    </Col>
  )
}

export default ProductVariations;