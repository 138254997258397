export function StorageFactory(storage: Storage): Storage {
  let inMemoryStorage: { [key: string]: string } = {};
  let isBrowserStorageSupported: boolean = isSupported();
  let length = 0;

  /**
   * Checks if browser supports Web Storage API
   * @returns boolean
   */
  function isSupported(): boolean {
    try {
      const testKey = '__TEST_KEY__';
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  /**
   * Remove all items from storage
   */
  function clear(): void {
    if (isBrowserStorageSupported) {
      storage.clear();
    } else {
      inMemoryStorage = {};
    }
  }

  /**
   * Get stored item from storage based on key value, if present
   * 
   * If item has expiry value, return it if it's valid, delete it otherwise
   * @param key
   * @returns stored item based on key or null
   */
  function getItem(key: string): string | null {
    let cachedItem: string | null = null;
    if (isBrowserStorageSupported) {
      cachedItem = storage.getItem(key);
    } else if (inMemoryStorage.hasOwnProperty(key)) {
      cachedItem = inMemoryStorage[key];
    }
    if (!cachedItem) {
      return null;
    }
    const item = JSON.parse(cachedItem);
    if (!item.hasOwnProperty('expiry')) {
      return item;
    }
    const now = new Date();
    if (now.getTime() > item.expiry) {
      storage.removeItem(key);
      return null;
    }
    return item.value;
  }

  /**
   * Get stored key from storage based on index value, if present
   * @param index
   * @returns key based on index or null
   */
  function key(index: number): string | null {
    if (isBrowserStorageSupported) {
      return storage.key(index);
    } else {
      return Object.keys(inMemoryStorage)[index] || null;
    }
  }

  /**
   * Remove stored item from storage based on key value, if present
   * @param key
   */
  function removeItem(key: string): void {
    if (isBrowserStorageSupported) {
      storage.removeItem(key);
    } else {
      delete inMemoryStorage[key];
    }
  }


  /**
   * Store item in storage for key, override item if present
   * @param key
   * @param value
   * @returns stored item based on key or null
   */
  function setItem(key: string, value: string): void {
    if (isBrowserStorageSupported) {
      storage.setItem(key, value);
    } else {
      inMemoryStorage[key] = String(value);
    }
  }

  /**
   * Store item with expiry time in storage for key, override item if present
   * @param key
   * @param value
   * @param ttlH - time to live in hour
   * @returns stored item based on key or null
   */
  function setItemWithTTL(key: string, value: string, ttlH: number = 1): void {
    const now = new Date();
    const ttlMS = ttlH * 36e5;
    const item = JSON.stringify({ value, expiry: now.getTime() + ttlMS });
    storage.setItem(key, item);
  }

  return {
    getItem,
    setItem,
    setItemWithTTL,
    removeItem,
    clear,
    key,
    length,
  };
}
