import { useLayoutEffect, useMemo, useState } from 'react';
import { useLoaderData } from 'react-router-dom';
import MaterialReactTable, { MRT_ColumnDef } from 'material-react-table';
import ProductService from '../../services/ProductService';
import PopupService from '../../services/PopupService';
import YextService from '../../services/YextService';
import { Defaults, priceColumnsConfig, Utils } from '../../helpers';
import { createClubPrices, IAgreementPriceDetails, ILocation } from '../../models';

const ClubPrices = () => {
  const columns = useMemo<MRT_ColumnDef<ILocation>[]>(() => priceColumnsConfig as MRT_ColumnDef<ILocation>[], []);
  const clubs = useLoaderData() as ILocation[];
  const [tableHeight, setTableHeight] = useState(Defaults.TableHeight);

  useLayoutEffect(() => {
    const handleResize = () => { setTableHeight(Utils.getAvailableViewportHeight(window, document)); }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); };
  }, []);

  return (
    <div className='clubs-container'>
      <div className='header' id='header'>
        <div className='title'>{Utils.getRegionName(clubs[0].isoRegionCode)}</div>
        <div className='info'>Total Clubs: <strong>{clubs.length}</strong></div>
      </div>
      <MaterialReactTable
        columns={columns}
        data={clubs}
        enableHiding={false}
        enablePagination={false}
        enableStickyHeader
        initialState={{ density: 'compact' }}
        muiTableBodyProps={{ sx: { '& tr:nth-of-type(odd)': { backgroundColor: '#eee', } }, }}
        muiTableContainerProps={{ sx: { maxHeight: tableHeight } }}
        muiTableProps={{ sx: { tableLayout: 'fixed' } }}
      />
    </div>
  )
}

export const clubPricesLoader = async (request: any) => {
  PopupService.showLoader('Getting Prices');
  const { id: regionId } = request.params;
  const { response: { entities } } = await YextService.getFacilitiesByRegion(regionId);
  const clubs = await fillClubDetails(entities);
  PopupService.hideLoader();
  return clubs;
}

const fillClubDetails = async (clubs: ILocation[]): Promise<ILocation[]> => {
  const response = [];
  for (const club of clubs) {
    let agreementsPrices: IAgreementPriceDetails[];
    try {
      const planPrices = await ProductService.getPrices(club.meta.id);
      agreementsPrices = planPrices.prices;
    } catch {
      agreementsPrices = [];
    }
    const prices = createClubPrices(agreementsPrices);
    response.push({ ...club, prices });
  }
  return response;
};

export default ClubPrices;