import { useState } from 'react';
import { LoaderFunctionArgs, useLoaderData, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { Dropdown, DropdownButton, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import FacilityService from '../../services/FacilityService';
import PopupService from '../../services/PopupService';
import YextService from '../../services/YextService';
import { StorageFactory } from '../../services/StorageFactory';
import { Defaults, FundingSource, Utils, errorMessages } from '../../helpers';
import { IFacilityLoaderData, IRegion } from '../../models';
import PriceTable from '../../components/priceTable';
import UpdateProductsError from './error';
import './style.scss';

const UpdateProducts = () => {

  const { facilityData, regions } = useLoaderData() as IFacilityLoaderData;
  const { state: { name, shortName: planClassName } } = useLocation();
  const params = useParams();
  const planName = Utils.capitalizeFirstLetter(params.planName);
  const [selectedRegion, setSelectedRegion] = useState(Defaults.Region as string);
  const [searchParams] = useSearchParams();
  const [fundingSource, setFundingSource] = useState(searchParams.get('fundingSource') as FundingSource);
  const [facilitiesCCData, setFacilitiesCCData] = useState(facilityData[FundingSource.CREDIT_CARD]);
  const [facilitiesACHData, setFacilitiesACHData] = useState(facilityData[FundingSource.BANK_ACCOUNT]);
  const [error, setError] = useState({ hasError: false, message: '' });

  const handleSelect = async (region: string | null): Promise<void> => {
    if (!region) {
      throw new Error('Unexpected error: region is null');
    }
    setFacilitiesCCData([]);
    setFacilitiesACHData([]);
    setSelectedRegion(region);
    try {
      PopupService.showLoader('Getting facility details');
      await FacilityService.updateFacilitiesData(region, setFacilitiesCCData, setFacilitiesACHData, planName);
    } catch (error: unknown) {
      setError({ hasError: true, message: errorMessages.fetchingRegionData });
    } finally {
      PopupService.hideLoader();
    }
  };

  /**
   * Updates queryParam with selected funding source
   * @param fundingSource
   * @returns Promise<void>
   */
  const handleFundingSourceToggle = async (fundingSource: FundingSource): Promise<void> => {
    setFundingSource(fundingSource);
    const url = new URL(window.location as any);
    url.searchParams.set('fundingSource', fundingSource);
    window.history.replaceState({}, '', url);
  }

  return error.hasError ? <UpdateProductsError message={error.message} /> : (<div className='update-products-container' data-testid='update-products-component'>
    <div className='products-header' id='header'>
      <div className='alert-message'>Enabling or Disabling the bundles can take up to <span>10-15 minutes</span> to be reflected due to price-caching mechanism</div>
      <div className='w-100 d-flex justify-content-between align-items-center mb-3'>
        <DropdownButton
          className={planClassName}
          id='dropdown-basic-button'
          data-testid='select-region-dropdown-button'
          title={Utils.getRegionName(selectedRegion)}
          onSelect={handleSelect}
        >
          {regions.map((region) => <Dropdown.Item key={region.id} eventKey={region.name}>{Utils.getRegionName(region.name)}</Dropdown.Item>)}
        </DropdownButton>
        <ToggleButtonGroup type='radio' name='options' value={fundingSource} onChange={handleFundingSourceToggle} data-testid='change-funding-source-toggle-button'>
          <ToggleButton id={`${name}#CC`} value='CC' className={`me-2 toggle-btn ${planClassName}`} data-testid='cc-funding-source-toggle-button'>
            {`${name} Monthly CC`}
          </ToggleButton>
          <ToggleButton id={`${name}#ACH`} value='ACH' className={`toggle-btn ${planClassName}`} data-testid='ach-funding-source-toggle-button'>
            {`${name} Monthly ACH`}
          </ToggleButton>
        </ToggleButtonGroup>
        <div></div>
      </div>
      <div><h5>Select all the clubs you want to activate bundles for:</h5></div>
    </div>
    <PriceTable facilitiesDataProp={fundingSource === FundingSource.CREDIT_CARD ? [facilitiesCCData, setFacilitiesCCData] : [facilitiesACHData, setFacilitiesACHData]} planName={planName} planClassName={planClassName} fundingSource={fundingSource} selectedRegion={selectedRegion} setError={setError} key={`${selectedRegion}#${fundingSource}`} />
  </div>);
}

/**
 * Loading the yext region data, utilizing storage if cached data is present
 * @returns Promise<IFacilityLoaderData[]>
 */
export const updateProductsLoader = async (facilityLoaderArg: LoaderFunctionArgs): Promise<IFacilityLoaderData> => {
  PopupService.showLoader('Getting facility details');
  const { params: { planName } } = facilityLoaderArg;
  if (!planName) {
    throw new Error(`Unexpected error: Unable to retrieve planName`);
  }

  const cacheKey = 'YEXT_ALL_REGION_DATA';
  const sessionStore = StorageFactory(sessionStorage);
  let regions: IRegion[] = JSON.parse(sessionStore.getItem(cacheKey)!);

  try {
    if (!regions) {
      const { response: { folders } } = await YextService.getAllRegions();
      regions = folders.filter((folder: IRegion) => folder.name !== 'Blink Fitness');
      sessionStore.setItemWithTTL(cacheKey, JSON.stringify(regions), 200);
    }
    const facilityData = await FacilityService.getfacilitiesData(Defaults.Region, planName);
    return { facilityData, regions };
  } catch (error: any) {
    throw new Error(error.message);
  } finally {
    PopupService.hideLoader();
  }
};

export default UpdateProducts;
