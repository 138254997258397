import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MaterialReactTable, { type MRT_ColumnDef } from 'material-react-table';
import { Edit, Timer } from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { promoCodesActions } from '../../store/promo-code-slice';
import { createPromoCode, updatePromoCode, expirePromoCode, fetchPromoCodes } from '../../store/promo-code-actions';
import ExpirePromoCode from '../../components/ExpirePromoCode';
import PromoCodeForm from '../../components/PromoCodeForm';
import PopupService from '../../services/PopupService';
import { promoCodesColumnsConfig, PromoCodeEvents, Utils, Defaults } from '../../helpers';
import { IPromoCode } from '../../models';
import { UnknownAction } from '@reduxjs/toolkit';

const PromoCodes = () => {
  const dispatch = useDispatch();
  const columns = useMemo<MRT_ColumnDef<IPromoCode>[]>(() => promoCodesColumnsConfig as MRT_ColumnDef<IPromoCode>[], []);
  const [tableHeight, setTableHeight] = useState(Defaults.TableHeight);

  useEffect(() => {
    dispatch(fetchPromoCodes() as unknown as UnknownAction);
  }, [dispatch]);

  useLayoutEffect(() => {
    const handleResize = () => { setTableHeight(Utils.getAvailableViewportHeight(window, document)); }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); };
  }, []);

  const { items: promoCodes, event, promoCode } = useSelector((state: any) => state.promoCodes);
  const [open, setOpen] = useState(false);
  const [openExpiryDialog, setOpenExpiryDialog] = useState(false);

  const handleCreate = () => {
    dispatch(promoCodesActions.createPromoCodeEvent());
    setOpen(true);
  };

  const handleEdit = ({ original: data }: { original: IPromoCode }) => {
    dispatch(promoCodesActions.updatePromoCodeEvent(data));
    setOpen(true);
  };

  const handleExpiry = ({ original: data }: { original: IPromoCode }) => {
    dispatch(promoCodesActions.expirePromoCodeEvent(data));
    setOpenExpiryDialog(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenExpiryDialog(false);
  };

  const handleSubmit = async (data: IPromoCode) => {
    PopupService.showLoader(event.loader);
    setOpen(false);
    setOpenExpiryDialog(false);
    if (event.type === PromoCodeEvents.create.type) {
      dispatch(createPromoCode(data) as unknown as UnknownAction);
    }
    if (event.type === PromoCodeEvents.update.type) {
      dispatch(updatePromoCode(data) as unknown as UnknownAction);
    }
    if (event.type === PromoCodeEvents.expire.type) {
      dispatch(expirePromoCode(data) as unknown as UnknownAction);
    }
  };

  return (
    <div className='promocodes-container' data-testid='promocodes'>
      <div className='header' id='header'>
        <div className='title'>Promocodes</div>
        <div className='info' data-testid='total-promocodes'>Total: <strong>{promoCodes.length}</strong></div>
      </div>
      <MaterialReactTable
        displayColumnDefOptions={{ 'mrt-row-actions': { muiTableHeadCellProps: { align: 'center' } } }}
        columns={columns}
        data={promoCodes}
        editingMode='modal'
        enableEditing
        enablePagination={false}
        enableStickyHeader
        initialState={{ density: 'compact' }}
        muiTableBodyProps={{ sx: { '& tr:nth-of-type(odd)': { backgroundColor: '#eee' } } }}
        muiTableContainerProps={{ sx: { maxHeight: tableHeight } }}
        muiTableProps={{ sx: { tableLayout: 'fixed' } }}
        renderRowActions={({ row }) => (
          <Box sx={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
            <Tooltip arrow placement='left' title='Edit'>
              <IconButton onClick={() => handleEdit(row)} data-testid={`edit-promocode-${row.id}-btn`}>
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement='right' title='Expire'>
              <IconButton color='error' onClick={() => handleExpiry(row)} data-testid={`expire-promocode-${row.id}-btn`}>
                <Timer />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <Button className='mx-3' data-testid='create-promocode-btn' color='primary' variant='contained' onClick={() => handleCreate()}>Create New Promocode</Button>
        )}
      />
      <PromoCodeForm
        open={open}
        data={promoCode}
        handleSubmit={(event: IPromoCode) => handleSubmit(event)}
        handleClose={handleClose}>
      </PromoCodeForm>
      <ExpirePromoCode
        open={openExpiryDialog}
        data={promoCode}
        handleSubmit={(event: IPromoCode) => handleSubmit(event)}
        handleClose={handleClose}>
      </ExpirePromoCode>
    </div>
  )
}

export default PromoCodes;
