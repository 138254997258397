export enum Plan {
  BLUE = 'BLUE',
  BLUE_NC = 'BLUE_NON-COMMIT',
  GRAY = 'GRAY',
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
}

export const planMap = new Map<string, string>([
  ['hasBlue', 'Blue'],
  ['hasBlueNC', 'Blue_Non-Commit'],
  ['hasGray', 'Gray'],
  ['hasGreen', 'Green'],
  ['hasOrange', 'Orange']
]);

export enum ObligatedQuantity {
  COMMIT = 12,
  NON_COMMIT = 1
}

export enum Region {
  NEW_YORK = 'ny'
}

export enum ClubType {
  FRANCHISE = 'FRANCHISE',
  CORPORATE = 'ny'
}

export enum MachineType {
  ELO = 'elo',
  GULLWING = 'gullwing'
}