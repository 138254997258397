import { YextLocations, Config } from '@blinkfitness/blink-yext-api';
import { ILocationOptions, ILocations, IRegionFolders } from '../models';

const liveAPIConfig = {
    apiKey: '',
    apiVersion: '20200320',
    accountId: 'me',
    baseUrl: 'https://liveapi.yext.com/v2/accounts',
    retryCount: 3,
    rateLimitRetry: false
};

const defaultFields = [
  'name',
  'geomodifier',
  'c_clubType',
  'c_status',
  'c_planOptions',
  'isoRegionCode'
]

const requestWithOption = (options?: ILocationOptions): YextLocations => {
  const config: any = Config.newConfig(liveAPIConfig);
  config.setApiKey(String(process.env.REACT_APP_YEXT_LIVE_API_KEY))
  const yext = new YextLocations(config);
  return yext.setFields(defaultFields);
};

const YextService = {
  getAllFacilities: function (options?: ILocationOptions): Promise<ILocations> {
    const yext: YextLocations = requestWithOption(options);
    return yext.getAll();
  },
  getFacilitiesByRegion: function (state: string, options?: ILocationOptions): Promise<ILocations> {
    const yext: YextLocations = requestWithOption({ ...options });
    yext.setFilters({ isoRegionCode: state });
    return yext.getAll();
  },
  getAllRegions: async function (): Promise<IRegionFolders> {
    const url = `${process.env.REACT_APP_DOMAIN_URL}/${process.env.REACT_APP_YEXT_API_URL}`;
    const response = await fetch(`${url}/yext-folders`, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'content-type': 'application/json',
        'x-api-key': String(process.env.REACT_APP_YEXT_API_KEY)
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer'
    });
    return response.json();
  }
};

export default YextService;