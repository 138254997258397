import axios from 'axios';
import ProductService from './ProductService';
import YextService from './YextService';
import { Utils } from '../helpers';
import { FundingSource, HttpStatusCode, PlanStatus } from './../helpers/constants';
import { IFacilityData, Product, IProductDetails, PriceItem, ILocation, ISingleFacilityData, IAgreementPriceDetails, IFacilityFactoryData, IFacilityListData, IUpdatePlanPayload, IUpdateBundleStatusResponse, IFacilityFundingSourceData } from '../models';

const FacilityService = {
  /**
   * Formats yext data to be used by MaterialReactTable
   * @param yextEntities : ILocation[]
   * @returns IFacilityListData[]
   */
  formatFacilityListData: function (yextEntities: ILocation[]): IFacilityListData[] {
    return yextEntities.map(entity => ({
      name: entity.geomodifier,
      id: entity.meta.id,
    }));
  },

  /**
   * Fetches facility list present in selected region
   * @param selectedRegion 
   * @returns Promise<IFacilityFundingSourceData[]>
   */
  getFacilitiesDataByRegion: async function (selectedRegion: string): Promise<IFacilityListData[]> {
    try {
      const { response: { entities } } = await YextService.getFacilitiesByRegion(selectedRegion);
      const formattedFacilityListData = FacilityService.formatFacilityListData(entities);
      return formattedFacilityListData;
    } catch (error: any) {
      throw Error('Error occurred while fetching facilities data');
    }
  },

  /**
   * fetch bundle details for facility
   * @param price 
   * @param facility 
   * @param defaultFacilityData 
   */
  fetchBundleDetails: async function (price: IAgreementPriceDetails, facility: IFacilityListData, defaultFacilityData: IFacilityFactoryData): Promise<void> {
    try {
      const { PricingDetail: { ItemDetail }, bundleId, fundingSource } = price;
      const { product } = await ProductService.getBundleDetails(facility.id, bundleId);
      const formattedProduct: IProductDetails = Utils.objectKeysToCamelCase(product);
      const clubPrices = PriceItem.createPriceItems(ItemDetail);

      for (const clubPrice of clubPrices) {
        defaultFacilityData[fundingSource].updateFees(clubPrice);
      }

      defaultFacilityData[fundingSource].planStatus = PlanStatus.ACTIVE;
      defaultFacilityData[fundingSource].defaultPromotion = formattedProduct?.promoName || 'No Default Promotion';
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error('Error occurred while fetching facilities data');
      }
      throw error;
    }
  },

  /**
   * Fetches facility prices & bundle details
   * @param facility 
   * @returns facility data to be rendered by table or null if api call fails
   */
  getFacilityData: async function (facility: IFacilityListData, selectedRegion: string, planName = ''): Promise<ISingleFacilityData> {
    const defaultFacilityData: IFacilityFactoryData = {
      [FundingSource.CREDIT_CARD]: new Product(facility, selectedRegion),
      [FundingSource.BANK_ACCOUNT]: new Product(facility, selectedRegion)
    };
    try {
      const { prices } = await ProductService.getPricesByPlanName(facility.id, planName);

      if (prices.length) {
        const fetchBundleDetailsPromises = prices.map(async price =>
          await FacilityService.fetchBundleDetails(price, facility, defaultFacilityData)
        );
        await Promise.all(fetchBundleDetailsPromises);
      }
      return defaultFacilityData;
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error('Error occurred while fetching facilities data');
      }
      throw error;
    }
  },

  /**
   * fetches all facility data for selected region
   * @param selectedRegion 
   * @param planName 
   * @returns Promise<IFacilityData>
   */
  fetchAndProcessFacilityData: async function (selectedRegion: string, planName: string): Promise<IFacilityData> {
    const fetchedFacilityData: IFacilityData = { [FundingSource.CREDIT_CARD]: [], [FundingSource.BANK_ACCOUNT]: [] };
    const formattedFacilityListData = await FacilityService.getFacilitiesDataByRegion(selectedRegion);
    if (formattedFacilityListData) {
      const facilityDataPromises = formattedFacilityListData.map(facility =>
        FacilityService.getFacilityData(facility, selectedRegion, planName)
      );
      const facilityDataResults = await Promise.allSettled(facilityDataPromises);
      for (const result of facilityDataResults) {
        if (result.status === 'fulfilled') {
          fetchedFacilityData[FundingSource.CREDIT_CARD].push(result.value[FundingSource.CREDIT_CARD]);
          fetchedFacilityData[FundingSource.BANK_ACCOUNT].push(result.value[FundingSource.BANK_ACCOUNT]);
        }
      }
    }
    return fetchedFacilityData;
  },

  /**
   * Get facilities data on initial load
   * @param selectedRegion 
   * @param planName 
   * @returns Promise<IFacilityData[]>
   */
  getfacilitiesData: async function (selectedRegion: string, planName: string): Promise<IFacilityData> {
    try {
      return await FacilityService.fetchAndProcessFacilityData(selectedRegion, planName);
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error('Error occurred while fetching facilities data');
      }
      throw error;
    }
  },

  /**
   * Update facilities data
   * @param selectedRegion 
   * @param setFacilitiesCCData 
   * @param setFacilitiesACHData 
   * @param planName 
   * @returns Promise<void>
   */
  updateFacilitiesData: async function (
    selectedRegion: string,
    setFacilitiesCCData: React.Dispatch<React.SetStateAction<IFacilityFundingSourceData[]>>,
    setFacilitiesACHData: React.Dispatch<React.SetStateAction<IFacilityFundingSourceData[]>>,
    planName: string
  ): Promise<void> {
    try {
      const newFacilitiesData = await FacilityService.fetchAndProcessFacilityData(selectedRegion, planName);
      setFacilitiesCCData([...newFacilitiesData[FundingSource.CREDIT_CARD]]);
      setFacilitiesACHData([...newFacilitiesData[FundingSource.BANK_ACCOUNT]]);
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error('Error occurred while fetching facilities data');
      }
      throw error;
    }
  },

  /**
   * Update facility bundle status
   * @param payload 
   * @returns Promise<IUpdateBundleStatusResponse>
   */
  updateBundleStatus: async function (payload: IUpdatePlanPayload): Promise<IUpdateBundleStatusResponse> {
    try {
      const response = await ProductService.updateBundleStatus(payload);
      if (response.status === HttpStatusCode.OK) {
        return { success: true };
      }
      return { success: false };
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        return { success: false, message: error.response?.data.message };
      } else {
        return { success: false };
      }
    }
  }
}

export default FacilityService;