export const errorMessages = {
  clubsUpdateFailed: 'Failed to update the clubs images.',
  fetchingRegionData: 'Error occurred while fetching selected region data',
  generic: 'Something went wrong! Please try again.',
  imageUploadFailed: 'Failed to upload the images.',
  invalidSession: 'Your session has been expired. Please sign-in again.',
  updatingBundleStatus: 'Error occurred while updating bundle status'
}

export const responseMessages = {
  productNotExists: 'The product does not exist in the catalog',
  screenSaverUpdated: 'Screensaver updated successfully'
}