import { createSlice } from '@reduxjs/toolkit';
import { ILocation } from '../models';

type ClubsState = {
  items: IClubRegion[];
};

type IClubRegion = {
  [key: string]: ILocation[];
};

const initialState: ClubsState = { items: []};

const clubsSlice = createSlice({
  name: 'clubs',
  initialState,
  reducers: {
    replaceClubRegions(state, action) {
      state.items = action.payload;
    }
  }
});

export const clubsActions = clubsSlice.actions;

export default clubsSlice.reducer;