import { Route, createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import RootLayout from './layouts/RootLayout';
import Homepage from './pages/Homepage';
import ClubGroups, { clubGroupsLoader } from './pages/ClubGroups';
import ClubsGroupsError from './pages/ClubGroups/error';
import ClubGroupPrices, { clubGroupPricesLoader } from './pages/ClubGroupPrices';
import ClubGroupPricesError from './pages/ClubGroupPrices/error';
import ClubPrices, { clubPricesLoader } from './pages/ClubPrices';
import ClubPricesError from './pages/ClubPrices/error';
import MigrateProducts, { migrateProductsLoader } from './pages/MigrateProducts';
import MigrateProductsError from './pages/MigrateProducts/error';
import UpdateProducts, { updateProductsLoader } from './pages/UpdateProducts';
import UpdateProductsError from './pages/UpdateProducts/error';
import Products from './pages/Products';
import Regions from './pages/Regions';
import Screensaver from './pages/Screensaver';
import PromoCodes from './pages/PromoCodes';
import PromoCodesError from './pages/PromoCodes/error';
import ProductVariations from './pages/ProductVariations';
import '@aws-amplify/ui-react/styles.css';
import './App.scss';

const App = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<RootLayout />}>
        <Route path="/" element={<Homepage />} />
        <Route path="prices" element={<Regions />} />
        <Route path="prices/:id" element={<ClubPrices />} loader={clubPricesLoader} errorElement={<ClubPricesError />} />
        <Route path="prices/:id/groups" element={<ClubGroups />} loader={clubGroupsLoader} errorElement={<ClubsGroupsError />} />
        <Route path="prices/:id/groups/:group" element={<ClubGroupPrices />} loader={clubGroupPricesLoader} errorElement={<ClubGroupPricesError />} />
        <Route path="/screensaver" element={<Screensaver />} />
        <Route path="/promocodes" element={<PromoCodes />} errorElement={<PromoCodesError />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/migrate" element={<MigrateProducts />} loader={migrateProductsLoader} errorElement={<MigrateProductsError />} />
        <Route path="/products/variations" element={<ProductVariations />} errorElement={<UpdateProductsError />} />
        <Route path="/products/variations/:planName" element={<UpdateProducts />} loader={updateProductsLoader} errorElement={<UpdateProductsError />} />
      </Route>
    )
  );

  return (
    <RouterProvider router={router} />
  )
}

export default App;
