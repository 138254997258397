import axios, { RawAxiosRequestHeaders } from 'axios';
import { CustomError, ImageUploadFailed, InvalidSession } from '../classes/errors';
import UserService from './UserService';
import { MachineType } from '../helpers';
import { IImage, TScreenSaverResponse, IScreenSaverService, Images } from "../models";

class ScreenSaverService implements IScreenSaverService {
  private apiKey: string;
  private contentType: string;
  private url: string;
  private userAuthToken: string | undefined;

  constructor() {
    this.apiKey = String(process.env.REACT_APP_ADMIN_API_KEY);
    this.contentType = String(process.env.REACT_APP_CONTENT_TYPE);
    this.url = `${process.env.REACT_APP_DOMAIN_URL}/${process.env.REACT_APP_ADMIN_API}`;
  }

  /**
   * Uploading the images to S3 bucket in elo/gullwing folders
   * @param eloFiles 
   * @param gullwingFiles 
   */
  async uploadImages(eloFiles: FileList, gullwingFiles: FileList | null | undefined): Promise<void> {
    try {
      const { elo, gullwing } = await this.getPayload(eloFiles, gullwingFiles);
      if (!gullwing?.length) {
        await this.postImages(elo);
      } else {
        await this.postImages(elo, MachineType.ELO);
        await this.postImages(gullwing, MachineType.GULLWING);
      }
    } catch (error) {
      throw (error instanceof CustomError ? error : new ImageUploadFailed());
    }
  };

  /**
   * Updating the images entry in the Clubs Table for each club
   * @param files 
   * @returns Promise<TScreenSaverResponse>
   */
  async updateClubs(files: FileList): Promise<TScreenSaverResponse> {
    let images = [];
    for (const element of files) { images.push(element.name); }
    return await axios.patch(
      `${this.getUrl()}/clubs`,
      JSON.stringify({ images }),
      { headers: await this.getRequestHeaders() }
    );
  };

  /**
   * Getting the images payload to save in S3 bucket
   * @param eloFiles 
   * @param gullwingFiles 
   * @returns Promise<Images>
   */
  private async getPayload(eloFiles: FileList, gullwingFiles: FileList | null | undefined): Promise<Images> {
    const images = new Images();
    images.elo = this.readFiles(eloFiles);
    if (gullwingFiles) { images.gullwing = this.readFiles(gullwingFiles); }
    return new Promise((resolve) => { setTimeout(() => resolve(images), 500) });
  };

  /**
   * Reading the files and returning it in an array form
   * @param files 
   * @returns IImage[]
   */
  private readFiles(files: FileList): IImage[] {
    const imageFiles: IImage[] = [];
    for (const file of files) {
      const reader = new FileReader();
      reader.onload = () => { imageFiles.push({ name: file.name, base64: reader.result }); };
      reader.readAsDataURL(file);
    }
    return imageFiles;
  }

  /**
   * Executing the post request on the images and passing the {machineType} query-param conditionally
   * @param images 
   * @param machineType 
   */
  private async postImages(images: IImage[], machineType?: string): Promise<void> {
    for (let image of images) {
      await axios.post(
        `${this.getUrl()}/screensaver`,
        JSON.stringify(image),
        {
          headers: await this.getRequestHeaders(),
          ...(machineType && { params: { machineType } })
        }
      );
    }
  };

  private getApiKey(): string {
    return this.apiKey;
  };

  private getContentType(): string {
    return this.contentType;
  };

  private getUrl(): string {
    return this.url;
  };

  private async getRequestHeaders(): Promise<(RawAxiosRequestHeaders)> {
    const userAuthToken = await this.getUserAuthToken();
    return {
      'Authorization': `Bearer ${userAuthToken}`,
      'Content-Type': this.getContentType(),
      'x-api-key': this.getApiKey()
    };
  }

  private async getUserAuthToken(): Promise<string | undefined> {
    if (!this.userAuthToken) {
      const user = await UserService.getAuthenticatedUser();
      this.userAuthToken = user?.signInUserSession.idToken.jwtToken;
      if(!this.userAuthToken) { throw new InvalidSession()}
    }
    return this.userAuthToken;
  }
}
export default ScreenSaverService;