import { CustomError } from "./custom.error";
import { errorMessages } from "../../helpers";

export class InvalidSession extends CustomError {
  constructor(message = errorMessages.invalidSession) {
    super(message);
    this.message = message;
    this.name = 'InvalidSession';
  }
}
