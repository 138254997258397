import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import { Provider } from 'react-redux';
import store from './store';
Amplify.configure(awsExports);

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container!);

root.render(
  <StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </StrictMode>
);

