import { Auth } from "aws-amplify";
import { CognitoUser } from "../models";

const UserService = {
  user: null,
  async getAuthenticatedUser(): Promise<CognitoUser | null> {
    if(!this.user) { this.user = await Auth.currentAuthenticatedUser(); }
    return this.user;
  }
}

export default UserService;