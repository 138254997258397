import { Link, useLoaderData } from 'react-router-dom';
import PopupService from '../../services/PopupService';
import YextService from '../../services/YextService';
import { ClubType, Utils } from '../../helpers';
import { IClubGroups, ILocation } from '../../models';
import './style.scss';

const ClubGroups = () => {
  const clubGroups = useLoaderData() as IClubGroups;

  return (
    <div className='clubs-groups-container'>
      <h5>Click on the groups to see prices for specific <strong>{Utils.getRegionName(clubGroups.regionId.toUpperCase())}</strong> locations:</h5>
      <Link key='1-9' to='1-9'>1-9 ({clubGroups.from1to9.length})</Link>
      <Link key='a-b' to='a-b'>A-B ({clubGroups.fromAtoB.length})</Link>
      <Link key='c-f' to='c-f'>C-F ({clubGroups.fromCtoF.length})</Link>
      <Link key='g-l' to='g-l'>G-L ({clubGroups.fromGtoL.length})</Link>
      <Link key='m-r' to='m-r'>M-R ({clubGroups.fromMtoR.length})</Link>
      <Link key='s-z' to='s-z'>S-Z ({clubGroups.fromStoZ.length})</Link>
      <Link key='corporates' to='corporate'>Corporate ({clubGroups.corporate.length})</Link>
      <Link key='franchises' to='franchise'>Franchise ({clubGroups.franchise.length})</Link>
      <Link key='all' to={`/prices/${clubGroups.regionId}`}>All locations ({clubGroups.corporate.length + clubGroups.franchise.length})</Link>

    </div>
  )
}

export const clubGroupsLoader = async (request: any) => {
  PopupService.showLoader('Getting Clubs Groups');
  const { id: regionId } = request.params;
  const { response: { entities } } = await YextService.getFacilitiesByRegion(regionId);
  const clubGroups = getClubsGroups(regionId, entities);
  PopupService.hideLoader();
  return clubGroups;
}

const getClubsGroups = async (regionId: string, clubs: ILocation[]): Promise<IClubGroups> => {
  const response = {
    corporate: [],
    franchise: [],
    from1to9: [],
    fromAtoB: [],
    fromCtoF: [],
    fromGtoL: [],
    fromMtoR: [],
    fromStoZ: [],
    regionId
  } as IClubGroups;

  clubs.sort((a, b) => a.geomodifier < b.geomodifier ? -1 : 1);

  for (const club of clubs) {
    const firstCharacter = club.geomodifier[0];
    const clubType = club.c_clubType;
    if (clubType === ClubType.FRANCHISE) {
      response.franchise.push(club);
    } else {
      response.corporate.push(club);
    }
    if (/^[a-bA-B].*$/.test(firstCharacter)) {
      response.fromAtoB.push(club);
    } else if (/^[c-fC-F].*$/.test(firstCharacter)) {
      response.fromCtoF.push(club);
    } else if (/^[g-lG-L].*$/.test(firstCharacter)) {
      response.fromGtoL.push(club);
    } else if (/^[m-rM-R].*$/.test(firstCharacter)) {
      response.fromMtoR.push(club);
    } else if (/^[s-zS-Z].*$/.test(firstCharacter)) {
      response.fromStoZ.push(club);
    } else {
      response.from1to9.push(club);
    }
  }
  return response;
};

export default ClubGroups;