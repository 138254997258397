import { CustomError } from "./custom.error";
import { errorMessages } from "../../helpers";

export class ClubsUpdateFailed extends CustomError {
  constructor(message = errorMessages.clubsUpdateFailed) {
    super(message);
    this.message = message;
    this.name = 'ClubsUpdateFailed';
  }
}

export class ImageUploadFailed extends CustomError {
  constructor(message = errorMessages.imageUploadFailed) {
    super(message);
    this.message = message;
    this.name = 'ImageUploadFailed';
  }
}
