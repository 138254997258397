import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Utils } from '../helpers';
import { IPromoCode } from '../models';

const ExpirePromoCode = (props: any) => {
  const { event, promoCode } = useSelector((state: any) => state.promoCodes);
  const [endDate, setEndDate] = useState(promoCode.endDate);
  const [checked, setChecked] = useState(false);

  const handleEndDateChange = (newValue: Date | null) => {
    if (newValue) {
      const formattedEndDate = Utils.getFormattedDate(newValue);
      setEndDate(formattedEndDate);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setChecked(checked);
  };

  const handleSubmit = async () => {
    if(checked) {
      setEndDate(null);
    }
    const data = {
      ...promoCode,
      endDate,
      ...(checked && { endDate: null })
    } as unknown as IPromoCode;
    resetForm();
    props.handleSubmit(data);
  };

  const handleCancel = async () => {
    resetForm();
    props.handleClose();
  };

  const resetForm = () => {
    setEndDate(Utils.getFormattedDate());
    setChecked(false);
  }

  useEffect(() => {
    setEndDate(promoCode.endDate);
  }, [promoCode])

  return (
    <Dialog maxWidth="lg" open={props.open} onClose={props.handleClose} data-testid='expire-promocode-form'>
      <DialogTitle>{event.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{event.text}</DialogContentText>
        <Row className="text-center justify-content-center">
          <Col lg={10}>
            <Row className="mt-3">
              <Col lg={6}>
                <FormControl>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      disabled={checked}
                      label="End Date"
                      inputFormat="MM/dd/yyyy"
                      value={endDate}
                      onChange={handleEndDateChange}
                      renderInput={(params) => <TextField {...params} id="endDate" margin="dense" aria-describedby="end-date-helper-text" />}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Col>
              <Col lg={6}>
                <FormControlLabel className="mt-3 mx-0" control={<Checkbox checked={checked} onChange={handleChange} />}
                  label="Expire today" />
              </Col>
            </Row>
          </Col>
        </Row>
      </DialogContent>
      <DialogActions className="m-5">
        <Button className="mx-4 px-5 py-3" variant="secondary" onClick={handleCancel} data-testid='expire-promocode-cancel-btn'>Cancel</Button>
        <Button className="px-5 py-3" variant="primary" onClick={handleSubmit} data-testid='expire-promocode-submit-btn'>Submit</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ExpirePromoCode;
