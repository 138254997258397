import { Link, useRouteError } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const UpdateProductsError = ({ message }: { message?: string }): JSX.Element => {
  const error = useRouteError() as { message: string };

  return (
    <div className='error-container'>
      <div className='error'>
        <h3>{error?.message || message}</h3>
        <Link to='/products/variations'>
          <Button>Back to the Product Variations Page</Button>
        </Link>
      </div>
    </div>
  )
}

export default UpdateProductsError;
