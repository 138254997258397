import { useRef, useState } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { ScreenSaverFactory } from '../../classes/factories/screensaver';
import { ClubsUpdateFailed, CustomError } from '../../classes/errors';
import PopupService from '../../services/PopupService';
import { errorMessages } from '../../helpers';
import { TScreenSaverResponse } from '../../models';
import './style.scss';

const Screensaver = () => {
  const eloFiles = useRef<HTMLInputElement>(null);
  const gullwingFiles = useRef<HTMLInputElement>(null);
  const [sameImagesForGullwing, setSameImagesForGullwing] = useState(true);

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target: { checked } } = event;
    setSameImagesForGullwing(checked);
  };

  const resetForm = () => {
    eloFiles.current!.value = '';
    if (gullwingFiles.current) gullwingFiles.current.value = '';
  };

  const updateScreenSaver = async (): Promise<TScreenSaverResponse> => {
    const service = ScreenSaverFactory.create();
    PopupService.showLoader('Uploading the images');
    await service.uploadImages(eloFiles.current?.files!, gullwingFiles?.current?.files);
    PopupService.updateLoader('Updating the table entries');
    return await service.updateClubs(eloFiles.current!.files!);
  };

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!eloFiles.current?.value || (!sameImagesForGullwing && !gullwingFiles.current?.value)) {
      PopupService.showWarning('Please select images to upload');
    } else {
      try {
        const { data: { success, message } } = await updateScreenSaver();
        resetForm();
        if (!success) { throw new ClubsUpdateFailed(message); }
        PopupService.showSuccess(message);
      } catch (error) {
        PopupService.showError(error instanceof CustomError ? error.message : errorMessages.generic);
      }
    }
  }

  return (
    <Form onSubmit={(e) => { void submitHandler(e) }} className='screensaver-container px-5' data-testid='screensaver'>
      <Form.Group className='form-group'>
        <i className='bi bi-cloud-upload-fill'></i>
        <label>ELO Image</label>
        <Form.Control type='file' accept='image/png, image/jpg, image/jpeg' id='elo' multiple ref={eloFiles} data-testid='elo' />
      </Form.Group>
      <Form.Check type='checkbox' id='sameForGullwing' data-testid='sameForGullwing'
        checked={sameImagesForGullwing}
        label='Use same image(s) for Gullwing'
        onChange={handleCheck} />
      {!sameImagesForGullwing &&
        <Form.Group className='form-group'>
          <i className='bi bi-cloud-upload-fill'></i>
          <label>GULLWING Image</label>
          <Form.Control type='file' accept='image/png, image/jpg, image/jpeg' id='gullwing' multiple ref={gullwingFiles} data-testid='gullwing' />
        </Form.Group>
      }
      <Row className='text-center mt-5'>
        <Col>
          <Button variant='primary' type='submit' data-testid='submit'>
            Update Kiosk Screensaver
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export default Screensaver;
