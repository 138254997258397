import { TScreenSaverResponse } from "./response.model";

export interface IImage {
  name: string;
  base64: string | ArrayBuffer | null;
}

export class Images {
  elo: IImage[];
  gullwing: IImage[];

  constructor() {
    this.elo = [];
    this.gullwing = [];
  }
}

export interface IScreenSaverService {
  uploadImages(eloFiles: FileList, gullwingFiles: FileList | null | undefined): Promise<void>;
  updateClubs(files: FileList): Promise<TScreenSaverResponse>;
}