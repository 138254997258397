import axios from 'axios';
import UserService from './UserService';

const AdminService = {
  changeProductStatus: async function (agreementId: number|undefined, facilityId: string, status?: string): Promise<any> {
    const payload = {
      agreementId,
      facilityId,
      status: status ?? 'active'
    };

    const url = `${process.env.REACT_APP_DOMAIN_URL}/${process.env.REACT_APP_ADMIN_API}`;
    const user = await UserService.getAuthenticatedUser();
    const options = {
      headers: {
        'Authorization': `Bearer ${user?.signInUserSession.idToken.jwtToken}`,
        'Content-Type': String(process.env.REACT_APP_CONTENT_TYPE),
        'x-api-key': String(process.env.REACT_APP_ADMIN_API_KEY)
      }
    };
    const response = await axios.put(`${url}/products/${agreementId}/${facilityId}/status/${payload.status}`, '', options);
    return response.data;
  }
};

export default AdminService;