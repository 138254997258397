import { MRT_Cell } from "material-react-table";
import { IPlanRowData } from "../models";

const CheckIcon = ({ cell }: { cell: MRT_Cell<IPlanRowData>}) => {
  const planName = cell.id.split('_has')[1].toLowerCase();
  return (
    <span><i className={`bi ${planName}-icon ${cell.getValue() ? 'bi-check-circle-fill' : 'bi-dash'}`}></i></span>
  );
}

export default CheckIcon;
