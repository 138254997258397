import { Defaults, Utils } from "../helpers";


export interface IPromoCodePayload {
  endDate: string;
  maxUseCount: number;
  mosoPromoName: string;
  promoCode?: string;
  promoMosoId: number;
  startDate: string;
}

export interface IPromoCode {
  endDate: string;
  maxUseCount: number;
  mosoPromoName: string;
  promoCode: string;
  promoMosoId: number;
  startDate: string;
  status?: string;
  tableData?: { id: number};
  useCount?: number;
}

export class PromoCode implements IPromoCode {
  endDate: string;
  maxUseCount: number;
  mosoPromoName: string;
  promoCode: string;
  promoMosoId: number;
  startDate: string;
  status?: string;
  useCount?: number;

  constructor(data?: IPromoCode) {
    this.endDate = data?.endDate ?? Utils.getFormattedDate(new Date(Defaults.EndDate));
    this.maxUseCount = Number(data?.maxUseCount ?? Defaults.MaxUseCount);
    this.mosoPromoName = data?.mosoPromoName ?? '';
    this.promoCode = data?.promoCode ?? '';
    this.promoMosoId = Number(data?.promoMosoId ?? Defaults.PromoMosoId);
    this.startDate = data?.startDate ?? Utils.getFormattedDate();
  }
}

export interface IPromoCodeLog {
  memberId: number;
  facilityId: number;
  facilityName: string;
  memberEmail: string;
  memberName: string;
  planType: string;
  promoCode: string;
  promoCodeMoso: string;
  transactionDate: string;
  transactionTime: string;
}

export interface IDateRangePicker {
  key: string;
  endDate: Date;
  startDate: Date;
}

export interface IPromoCodeEvents {
  create: IPromoCodeEvent;
  expire: IPromoCodeEvent;
  update: IPromoCodeEvent;
  view: IPromoCodeEvent;  
}

export interface IPromoCodeEvent { 
  text: string;
  title: string;
  type: string;
}