import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

const Homepage = () => {
    return (
        <Col xs={12} className='home px-5'>
            <Row className='text-center justify-content-center'>
                <Col lg={3}>
                    <Link to='/prices' className='btn-large'>
                        <i className='bi bi-gear-wide-connected'></i>
                        <span>Club Prices</span>
                    </Link>
                </Col>
                <Col lg={3}>
                    <Link to='/screensaver' className='btn-large'>
                        <i className='bi bi-images'></i>
                        <span>Screen Saver</span>
                    </Link>
                </Col>
                <Col lg={3}>
                    <Link to='/promocodes' className='btn-large'>
                        <i className='bi bi-cash-coin'></i>
                        <span>Promo Codes</span>
                    </Link>
                </Col>
                <Col lg={3}>
                    <Link to='/products' className='btn-large'>
                        <i className='bi bi-box-seam'></i>
                        <span>Products</span>
                    </Link>
                </Col>
                {/* <Col lg={3}>
                    <Link to='/referrals' className='btn-large'>
                        <i className='bi bi-people-fill'></i>
                        <span>Referrals</span>
                    </Link>
                </Col> */}
            </Row>
        </Col>
    )
}

export default Homepage;