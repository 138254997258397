import { NavLink } from 'react-router-dom';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Authenticator } from '@aws-amplify/ui-react';

const Navigation = (props: any) => {

  return (
    <Navbar expand="lg">
      <Navbar.Brand href="/">&nbsp;</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <NavLink to="/prices">Club Prices</NavLink>
          <NavLink to="/screensaver">Kiosk Screensaver</NavLink>
          <NavLink to="/promocodes">Promo Codes</NavLink>
          <NavLink to="/products">Products</NavLink>
        </Nav>
      </Navbar.Collapse>
      <Authenticator>
        {({ signOut }) => (
          <Button variant="secondary" className="sign-out" onClick={signOut}>Sign out</Button>
        )}
      </Authenticator>
    </Navbar>
  );
}

export default Navigation;
