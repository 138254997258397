import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import './style.scss';

const Products = () => {
  return (
    <Col xs={12} className='home px-5' data-testid='products'>
      <Row className='text-center justify-content-center'>
        <Col lg={4}>
          <Link to='migrate' className='btn-large' data-testid='product-migrate'>
            <i className='bi bi-box-seam parent-icon'>
              <i className='bi bi-arrow-down-circle-fill child-icon'></i>
            </i>
            <span>Request Product Migrations</span>
          </Link>
        </Col>
        <Col lg={4}>
          <Link to='variations' className='btn-large' data-testid='product-variations'>
            <i className='bi bi-ui-checks-grid'></i>
            <span>Manage Product Variations</span>
          </Link>
        </Col>
      </Row>
    </Col>
  );
}

export default Products;