const Footer = () => {
  return (
    <footer className="footer">
    <div>
      <strong>Blink Admin </strong>
      <span>&copy; 2024</span>
    </div>
  </footer>
  )
  };

export default Footer;